<template>
  <v-card
    class="mx-auto"
    width="100%"
  >
    <v-container
      fill-height
      fluid
    >
      <loading
        :active.sync="requestLoading"
        :is-full-page="false"
      />
      <v-server-table
        class="pt-0"
        ref="table"
        name="natural_person/peopleTable"
        :columns="columns"
        :options="options"
      >
        <div slot="afterFilterWrapper">
          <v-card-title class="float-left pa-0">
            <v-app-bar
              flat
              class="white pa-0"
              elevate-on-scroll
              dense
            >
              <v-toolbar-title
                data-cy="person_listing_title"
              >
                {{ $t('person.listing.title') }}
              </v-toolbar-title>
              <v-spacer />
              <v-btn
                text
                color="primary"
                :to="{ name: 'NaturalPersonCreate' }"
                data-cy="to_NaturalPersonCreate_add_btn"
              >
                <v-icon left>
                  mdi-plus-outline
                </v-icon>
                {{ $t('person.listing.buttons.add') }}
              </v-btn>
            </v-app-bar>
          </v-card-title>
        </div>
        <div
          slot="ref_id"
          slot-scope="props"
        >
          {{ props.row.ref_id }}
        </div>
        <div
          slot="idens"
          slot-scope="props"
        >
          <div
            v-for="(iden, key) in props.row.idens"
            :key="'iden_' + key"
          >
            {{ iden.id_number }}
          </div>
        </div>
        <div
          slot="names"
          slot-scope="props"
        >
          <div><b>{{ props.row | natural_person_name(1, 1) }}</b></div>
          <div><small>{{ props.row | natural_person_name(2, 1) }}</small></div>
        </div>
        <div
          slot="emails"
          slot-scope="props"
        >
          <div
            v-for="(email, key) in props.row.emails"
            :key="key"
          >
            {{ email.email }}
          </div>
        </div>
        <div
          slot="tel"
          slot-scope="props"
        >
          {{ props.row.tel }}
        </div>
        <div
          slot="fax"
          slot-scope="props"
        >
          {{ props.row.fax }}
        </div>
        <div
          slot="status"
          slot-scope="props"
        >
          <!-- {{ props.row.status == 1 ? 'Approved' : (props.row.status == 2 ? 'Rejected' : 'Pending') }} -->
          {{ $t(`person.details.form.status.options.${ props.row.status == 1 ? 'active' : 'inactive' }`) }}
        </div>
        <div
          slot="updated_date"
          slot-scope="props"
        >
          <DateFormat
            :date="props.row.updated_date"
            :showtime="true"
          />
        </div>
        <div
          slot="batch_scan_date"
          slot-scope="props"
        >
          <DateFormat
            :date="props.row.batch_scan_date"
            :showtime="true"
          />
        </div>
        <div
          slot="create_date"
          slot-scope="props"
        >
          <DateFormat
            :date="props.row.create_date"
            :showtime="true"
          />
        </div>
        <div
          slot="action"
          slot-scope="props"
        >
          <v-btn
            icon
            class="ma-1"
            :to="{ name: 'NaturalPersonEdit', params: { np_id: props.row.id } }"
            :data-cy="`to_NaturalPersonEdit_btn_${props.row.id}`"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <PopupDelete
            v-slot:default="{ on, loading }"
            @yes="(done) => {
              $store.dispatch('natural_person/remove', props.row.id)
                .then(() => {
                  $refs.table.getData();
                })
                .catch((error) => { console.warn(error) })
                .finally(done); // notify popup stop loading and close
            }"
            data-cy="natural_person"
          >
            <v-btn
              icon
              color="error"
              class="ma-1"
              v-on="on"
              :loading="loading"
              :data-cy="`delete_np_btn_${props.row.id}`"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </PopupDelete>
        </div>
      </v-server-table>
    </v-container>
  </v-card>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import DateFormat from '@/components/common/DateFormat'
import PopupDelete from '@/components/popups/PopupDeleteConfirmation'

export default {
  components: {
    DateFormat,
    PopupDelete
  },
  computed: {
    ...mapGetters({
      options: 'natural_person/options'
    }),
    ...mapState('natural_person', {
      requestLoading: state => state.listing.requestLoading,
      columns: state => state.listing.columns
    })
  },
  data: () => {
    return {

    }
  },
  methods: {

  },
  created () {

  }
}
</script>

<style>
div#example1_paginate, div#example1_filter {
	text-align: right;
}
button.btn-sm.btn-block {
	width: 80px;
	float: left;
	margin: 0 5px;
}
</style>
